body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  /* background: url('./assets/background2.jpg'); */
  /* background-size: cover; */
  background-color: #e8fbff94;
}

a:hover {
  text-decoration: none;
  color: #1976d3;
}

a {
  color: black;
  font-size: 1.2em;
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.selectedNavLink {
  color: #1976d3 !important;
}

.nav-link {
  font-size: 1.3em;
}

.navbar-brand {
  margin-right: 0;
}

/* .navbar-expand-lg .navbar-nav {
  border-bottom: solid lightgray 1px;
} */

.box {
  width: 100px;
  height: 100px;
  background: #ff1c68;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.bg-light {
  background-color: white !important;
  padding-bottom: 5px;
  padding-top: 5px;
box-shadow: 0px 5px 9px -5px rgba(105,104,105,1);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  justify-content: center;
  color: red;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.homeColumn {
    padding-left: 0px;
    padding-right: 0px;
}

.homePageImage {
    border-radius: 100%;
}

.imageContainer {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 94vh;
    box-shadow: 10px 0px 24px -9px rgba(46,41,46,1);
    background-color: #1976d3;
    
}

.imageContentContainer {
    margin-top: calc(94vh / 4);
}

.brandText {
    font-size: 50px;
    letter-spacing: 4px;
    font-weight: 500;
    color: white;

}

@media screen and (max-width: 1060px) {
    p.brandText {
      font-size: 40px;
    }
  }

@media screen and (max-width: 600px) {
    p.brandText {
      font-size: 30px;
    }
  }

@media screen and (max-width: 230px) {
    p.brandText {
        font-size: 20px;
    }
}


.homeSecondary {
    margin-bottom: 0px;
    font-size: 17px;
    color: white;
}


.descriptionContainer {
    margin-top: calc(94vh / 3);
    padding-left: 10px;
}

@media screen and (max-width: 990px) {
    div.descriptionContainer {
        margin-top: 30px;
    }
    }

.descriptionText {
    font-size: 30px;
}

.bannerBorder {
    border-left: 6px solid #1976d3;
    padding-left: 20px;
}

.MuiAvatar-img-3 {
    width: 90% !important;
}

.profileContainer {
    margin-left: calc(50% - 90px)
}
.headerText {
    font-weight:'bold';
    letter-spacing:'10px';
    font-size: 1.85em;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    h2.headerText {
        font-size: 20px;
    }
    }
.secondaryText {
    font-size: 30px;
}

.areaContainer {
    margin-top: 50px;
    background-color: #1976d3;
    width: 100%;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.75);
}

.areaText {
    color: white;
    font-size: 23px;
}

.extraCompanyText {
    font-size: 20px;
    font-weight: bold;
    color: black;
}

.extraCompanyList {
    list-style-type: none;   
}

.banner {
    border-left: 3px solid #1976d3;
    padding-left: 20px;
}

.trainingSolutions {
    color: #1976d3;
    font-weight: 600;
}


.bulletPoint {
    font-weight: bold;
    font-size: 30px;
    color: #1976d3;
}


.listStyle {
    list-style-type: square;
}

li {
    color: #1976d3;
}

.bulletText span {
    color: black;
    font-size: 22px;
}
.logoText {
    /* text-align: center; */
    font-weight: bold;
    font-size: 15px;
    margin-top: 10px;
}


.footerContainer {
    margin-top: 80px;
    /* height: 200px; */
    background-color: #121212;
    text-align: center;
    color: white;
}
.aboutHeaderText {
    font-size: 27px;
    border-left: 6px solid #1976d3;
    padding-left: 20px;
}
.smallImage {
    width: 250px;
    /* border: 1px solid black; */
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.75);
}

.imageRow {
    text-align: center
}

.imageText {
    margin-top: 10px;
    font-weight: 600;
}
.subHeader {
    font-size: 20px;
    font-weight: 500;
}
