.secondaryText {
    font-size: 30px;
}

.areaContainer {
    margin-top: 50px;
    background-color: #1976d3;
    width: 100%;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.75);
}

.areaText {
    color: white;
    font-size: 23px;
}

.extraCompanyText {
    font-size: 20px;
    font-weight: bold;
    color: black;
}

.extraCompanyList {
    list-style-type: none;   
}

.banner {
    border-left: 3px solid #1976d3;
    padding-left: 20px;
}

.trainingSolutions {
    color: #1976d3;
    font-weight: 600;
}

