.smallImage {
    width: 250px;
    /* border: 1px solid black; */
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.75);
}

.imageRow {
    text-align: center
}

.imageText {
    margin-top: 10px;
    font-weight: 600;
}