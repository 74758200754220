body {
  /* background: url('./assets/background2.jpg'); */
  /* background-size: cover; */
  background-color: #e8fbff94;
}

a:hover {
  text-decoration: none;
  color: #1976d3;
}

a {
  color: black;
  font-size: 1.2em;
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.selectedNavLink {
  color: #1976d3 !important;
}

.nav-link {
  font-size: 1.3em;
}

.navbar-brand {
  margin-right: 0;
}

/* .navbar-expand-lg .navbar-nav {
  border-bottom: solid lightgray 1px;
} */

.box {
  width: 100px;
  height: 100px;
  background: #ff1c68;
  transform-origin: 50% 50%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.bg-light {
  background-color: white !important;
  padding-bottom: 5px;
  padding-top: 5px;
  -webkit-box-shadow: 0px 5px 9px -5px rgba(105,104,105,1);
-moz-box-shadow: 0px 5px 9px -5px rgba(105,104,105,1);
box-shadow: 0px 5px 9px -5px rgba(105,104,105,1);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  justify-content: center;
  color: red;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
