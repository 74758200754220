.bulletPoint {
    font-weight: bold;
    font-size: 30px;
    color: #1976d3;
}


.listStyle {
    list-style-type: square;
}

li {
    color: #1976d3;
}

.bulletText span {
    color: black;
    font-size: 22px;
}