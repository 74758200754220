.homeColumn {
    padding-left: 0px;
    padding-right: 0px;
}

.homePageImage {
    border-radius: 100%;
}

.imageContainer {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 94vh;
    -webkit-box-shadow: 10px 0px 24px -9px rgba(46,41,46,1);
    -moz-box-shadow: 10px 0px 24px -9px rgba(46,41,46,1);
    box-shadow: 10px 0px 24px -9px rgba(46,41,46,1);
    background-color: #1976d3;
    
}

.imageContentContainer {
    margin-top: calc(94vh / 4);
}

.brandText {
    font-size: 50px;
    letter-spacing: 4px;
    font-weight: 500;
    color: white;

}

@media screen and (max-width: 1060px) {
    p.brandText {
      font-size: 40px;
    }
  }

@media screen and (max-width: 600px) {
    p.brandText {
      font-size: 30px;
    }
  }

@media screen and (max-width: 230px) {
    p.brandText {
        font-size: 20px;
    }
}


.homeSecondary {
    margin-bottom: 0px;
    font-size: 17px;
    color: white;
}


.descriptionContainer {
    margin-top: calc(94vh / 3);
    padding-left: 10px;
}

@media screen and (max-width: 990px) {
    div.descriptionContainer {
        margin-top: 30px;
    }
    }

.descriptionText {
    font-size: 30px;
}

.bannerBorder {
    border-left: 6px solid #1976d3;
    padding-left: 20px;
}

.MuiAvatar-img-3 {
    width: 90% !important;
}

.profileContainer {
    margin-left: calc(50% - 90px)
}