.headerText {
    font-weight:'bold';
    letter-spacing:'10px';
    font-size: 1.85em;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    h2.headerText {
        font-size: 20px;
    }
    }